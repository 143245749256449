<template>
  <div class="home">
    <v-img src="@/assets/logo.png" contain height="80%"> </v-img>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  title:
    "R et M Interim - La bonne enterprise, le bon collaborateur, pour le bon poste",
};
</script>
<style scoped>
.home {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.content {
  width: 100%;
  max-width: 1200px; /* Defina a largura máxima desejada */
}
</style>
