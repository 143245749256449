<template>
  <v-template>
    <div class="body404">
      <v-container>
        <h1>Ooooops!!!</h1>
        <p>La page que vous recherchez est introuvable.</p>
        <p>
          Peut-être avez-vous suivi un lien incorrect ou la page a été
          supprimée.
        </p>
        <p>
          Revenez à la <a href="/">page d'accueil</a> pour trouver ce que vous
          cherchez.
        </p>
      </v-container>
      <v-container>
        <v-img src="@/assets/404.png" max-height="100%" max-width="100%"></v-img>
      </v-container>
    </div>
  </v-template>
</template>

<script>
export default {
  name: "NotFound",
  title: "Erreur 404 - Page non trouvée",
};
</script>

<style scoped>
.body404 {
  background-color: #ffffff;
  font-family: Arial, sans-serif;
  text-align: center;
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

h1 {
  color: #333333;
  font-size: 56px;
  font-family: "BomberTVDF", sans-serif;
  padding-bottom: 26px;
}

p {
  color: #3c3c3c;
  font-size: 18px;
  font-family: "TTNormsProExtraLight", sans-serif;
}

.emoji {
  font-size: 72px;
}
</style>
